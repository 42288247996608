<template>
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

        <div class="m-2">

            <!-- Table Top -->
            <b-row>

                <!-- Per Page -->
                <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                </b-col>

                <!-- Search -->
                <b-col
                    cols="12"
                    md="6"
                >
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input
                            v-model="search"
                            class="d-inline-block mr-1"
                            placeholder="Search..."
                            @keyup="searchCustomers"
                        />
                    </div>
                </b-col>
            </b-row>

        </div>

        <b-table
            :stacked="options.stacked"
            class="position-relative"
            :items="customers"
            striped
            responsive
            :fields="tableColumns"
            :per-page="options.perPageOptions"
            :current-page="options.currentPage"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
            id="tabla-clientes"
            @row-clicked="editCustomer"
        >

            <!-- Column: Actions -->
            <template #cell(actions)="data">
                <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                >

                    <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                    </template>
                    <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                        <feather-icon icon="FileTextIcon"/>
                        <span class="align-middle ml-50">Details</span>
                    </b-dropdown-item>

                    <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
                        <feather-icon icon="EditIcon"/>
                        <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>

                    <b-dropdown-item>
                        <feather-icon icon="TrashIcon"/>
                        <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>

        </b-table>
        <div class="mx-2 mb-2">
            <b-row>

                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                    <span class="text-muted">Mostrando {{ options.currentPage }} de {{ this.customers.length }} - {{ options.perPageOptions }}</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                    <b-pagination
                        v-model="options.currentPage"
                        :total-rows="rows"
                        :per-page="options.perPageOptions"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        aria-controls="tabla-clientes"
                    >
                        <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                    </b-pagination>

                </b-col>

            </b-row>
        </div>
    </b-card>
</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BTable, BPagination,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
    name: 'Customers',
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BTable,
        BPagination,
    },
    data() {
        return {
            customers: [],
            search: '',
            setTimeoutSearch: '',
            options: {
                stacked: false,
                perPageOptions: null,
                currentPage: 1,
                rows: null,
                filter: null,
            },
            tableColumns: [
                {
                    label: '',
                    key: 'show_details',
                    sortable: false,
                },
                {
                    label: 'Id',
                    key: 'clave',
                    sortable: true,
                },
                {
                    label: 'Nombre',
                    key: 'cli_exc',
                    sortable: true,
                },
                {
                    label: 'Fiscal',
                    key: 'nomfactura',
                    sortable: true,
                },
                {
                    label: 'Teléfono',
                    key: 'telefono',
                    sortable: false,
                },
                {
                    label: 'Email',
                    key: 'email',
                    sortable: false,
                },

            ],
            showTable: false,
            showDetails: false,
            dataId: '',
        }
    },
    mounted() {
        this.getClientes()
    },
    computed: {
        rows() {
            return this.customers.length
        },
    },
    methods: {
        getClientes() {
            const self = this
            useJwt.getCustomers({ search: self.search })
                .then(response => {
                    self.customers = response.data.customers
                    self.options.perPageOptions = response.data.pagination
                })
        },
        searchCustomers() {
            clearTimeout(this.setTimeoutSearch)
            this.setTimeoutSearch = setTimeout(this.getClientes, 300)
        },
        editCustomer(customer) {
            let idCustomer = customer.clave
            this.$router.push(
                {
                    name: 'Detalles Cliente',
                    params: {idCustomer}
                });
        },
    },
}
</script>

<style>

</style>
